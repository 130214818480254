import React from 'react';
import { Tilt } from 'react-tilt';
import Card from '../components/Card';
import CardContainer from '../components/CardContainer';
import Footer from '../components/Footer';
import { bradleyImage, tempImage, tjhsst, northeastern, london, mitre, chenega, cyberstar, instagram, github, linkedin } from '../assets/imageImport';

const Home = () => {
  const handleClick = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  const educationCards = [
    <Card 
      image={tjhsst} 
      title="TJHSST" 
      description="Thomas Jefferson High School for Science and Technology" 
      timeRange="2018-2022" 
      link="https://tjhsst.fcps.edu/" 
    />,
    <Card 
      image={london} 
      title="Northeastern London" 
      description="Year abroad in London studying computer science" 
      timeRange="2022-2023" 
      link="https://www.nulondon.ac.uk/" 
    />,
    <Card 
      image={northeastern} 
      title="Northeastern University" 
      description="Combined major in mathematics and computer science" 
      timeRange="2022-2026" 
      link="https://www.northeastern.edu/" 
    />,
  ];

  const internshipCards = [
    <Card 
      image={mitre} 
      title="MITRE" 
      description={[
        "Researched, developed, and deployed brand new technology on a classified full stack web development project",
        "Optimized Flask API performance and AJAX web calls to improve the loading times of 4 key pages by more than 100x",
        "Built standardized modal blueprint used throughout the entire app, allowing for the deletion of 18 files and removing 1000s of unnecessary lines of code"
      ]}
      timeRange="May-December 2024" 
      link="https://www.mitre.org/"
      isList={true}
    />,
    <Card 
      image={chenega} 
      title="Chenega MIOS" 
      description={[
        "Created Microsoft Power Automate flows to automate employee record keeping, these flows saved hundreds of hours throughout the company, removed redundancies by centralizing 3 data locations, and cut out human error",
        "Developed a Microsoft Power App to centralize, simplify, and track a budget of over $2 million, which is currently being used by over 40 employees daily",
        "Created a comprehensive Data Visualization page of the company budget using Microsoft Power BI, providing executives with real-time insights into financial performance"
      ]}
      timeRange="Summer 2023 and 2022" 
      link="https://chenegamios.com/" 
      isList={true}
    />,
    <Card 
      image={cyberstar} 
      title="Cyberstar" 
      description={[
        "Edited technical jargon in more than 15 RFIs and government contracts, assisting the company to reach a record number of accepted contracts that quarter",
        "Independently learned and implemented UIpath automation software and trained 4 employees to use it"
      ]}
      timeRange="Summer 2021" 
      link="https://www.cyberstar.com/" 
      isList={true}
    />,
  ];

  return (
    <>
      <section id="intro" className="vh-100 d-flex justify-content-center">
        <div className="container-md d-flex justify-content-center">
          <div className="row m-5 p-5 bg-light rounded">
            <div className="col d-flex flex-column justify-content-center align-items-center m-2">
              <button className="btn custom-btn mb-3" onClick={() => handleClick('education-section')}>Education</button>
              <button className="btn custom-btn" onClick={() => handleClick('internships-section')}>Internships and CO-OPs</button>
            </div>
            <div className="col mh-100 m-2 d-flex justify-content-center align-items-center">
              <Tilt className="Tilt" options={{ max: 25, scale: 1.05 }}>
                <div className="Tilt-inner">
                  <img src={bradleyImage} className="img-fluid rounded mh-100" alt="Bradley Harvan" />
                </div>
              </Tilt>
            </div>
          </div>
        </div>
      </section>
      <section id="education-section">
        <CardContainer title="Education" cards={educationCards} />
      </section>
      <section id="internships-section">
        <CardContainer title="Internships and CO-OPs" cards={internshipCards} />
      </section>
      <Footer></Footer>
    </>
  );
};

export default Home;