import React from 'react';
import PropTypes from 'prop-types';

const CardContainer = ({ title, cards }) => {
  return (
    <section className="card-container-section">
      <div className="container bg-light rounded p-4">
        <h2 className="text-center mb-4">{title}</h2>
        <div className="row justify-content-center">
          {cards.map((card, index) => (
            <div key={index} className="col-md-4 d-flex justify-content-center mb-4">
              {card}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
CardContainer.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.element).isRequired,
};
export default CardContainer;