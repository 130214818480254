import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const TopNavbar = () => {
  return (
    <Navbar expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="#">BradleyHarvan.com</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} exact to="/" activeClassName="font-weight-bold">
              Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/boids" activeClassName="font-weight-bold">
              Boids
            </Nav.Link>
            <Nav.Link as={NavLink} to="/timeline" activeClassName="font-weight-bold">
              Timeline
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;