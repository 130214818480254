import React from 'react';
import { Tilt } from 'react-tilt';
import PropTypes from 'prop-types';

const Card = ({ image, title, description, timeRange, link, isList, customClass }) => {
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <Tilt className="Tilt" options={{ max: 25, scale: 1.05 }}>
      <div className={`Tilt-inner card rounded ${customClass}`} onClick={handleClick} style={{ cursor: link ? 'pointer' : 'default' }}>
        <img src={image} className="card-img-top rounded-top" alt="Card cap" />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          {isList ? (
            <ul className="card-text">
              {description.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : (
            <p className="card-text">{description}</p>
          )}
          <p className="card-time-range">{timeRange}</p>
        </div>
      </div>
    </Tilt>
  );
};

Card.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  timeRange: PropTypes.string.isRequired,
  link: PropTypes.string,
  isList: PropTypes.bool,
  customClass: PropTypes.string,
};

Card.defaultProps = {
  isList: false,
  customClass: '',
};

export default Card;