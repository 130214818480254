import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaLinkedin, FaGithub, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-light text-center text-lg-start">
      <Container className="p-4">
        <Row className="align-items-center">
          <Col lg={6} className="mb-4 mb-lg-0 text-lg-start text-center">
            © {new Date().getFullYear()} Bradley Harvan. All rights reserved.
          </Col>
          <Col lg={6} className="mb-4 mb-lg-0 text-lg-end text-center">
            <div className="d-flex justify-content-center justify-content-lg-end">
              <a href="https://intagram.com/brad_loaf" className="me-4 text-reset">
                <FaInstagram size={30} />
              </a>
              <a href="https://linkedin.com/in/bradley-harvan" className="me-4 text-reset">
                <FaLinkedin size={30} />
              </a>
              <a href="https://github.com/BradLoaf" className="me-4 text-reset">
                <FaGithub size={30} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;