import React, { useEffect, useRef } from 'react';
const Boids = () => {
    const canvasRef = useRef(null);
    const ctx = useRef(null);
    const speed_limit = useRef(3);
    const view_distance = useRef(200);
    const avoid_distance = useRef(20);
    const center_weight = useRef(0.005);
    const avoid_weight = useRef(0.05);
    const matching_weight = useRef(0.05);
    const turn_weight = useRef(1);
    let boids = [];
    let navbarHeight = 0;
    let obstacles = [];
    let cursor = [];
    let width = window.innerWidth;
    let height = window.innerHeight;
    let margin = 150;
    let heightMin = 0;
    class Boid {
        constructor(x, y, dx, dy, color) {
            this.x = x;
            this.y = y;
            this.dx = dx;
            this.dy = dy;
            this.color = color;
            this.neighbors = [];
            this.avoid_neighbors = [];
        }
        find_neighbors() {
            this.neighbors = [];
            this.avoid_neighbors = [];
            for (let neighbor of boids) {
                if (neighbor !== this) {
                    let dist = distance(this, neighbor);
                    if (dist <= view_distance.current) {
                        if (this.color === neighbor.color)
                            this.neighbors.push(neighbor);
                        else
                            this.avoid_neighbors.push(neighbor);
                        if (dist <= avoid_distance.current)
                            this.avoid_neighbors.push(neighbor);
                    }
                }
            }
        }
        cluster() {
            let x = 0;
            let y = 0;
            for (let neighbor of this.neighbors) {
                x += neighbor.x;
                y += neighbor.y;
            }
            if (this.neighbors.length > 0) {
                x /= this.neighbors.length;
                y /= this.neighbors.length;
                this.dx += (x - this.x) * center_weight.current;
                this.dy += (y - this.y) * center_weight.current;
            }
        }
        avoid() {
            let x = 0;
            let y = 0;
            for (let neighbor of this.avoid_neighbors) {
                x += this.x - neighbor.x;
                y += this.y - neighbor.y;
            }
            this.dx += x * avoid_weight.current;
            this.dy += y * avoid_weight.current;
        }
        avoidMouse() {
            let x = 0;
            let y = 0;
            if (Math.sqrt((this.x - cursor[0]) ** 2 + (this.y - cursor[1]) ** 2) <= view_distance.current / 2.5) {
                x += this.x - cursor[0];
                y += this.y - cursor[1];
            }
            this.dx += x * avoid_weight.current;
            this.dy += y * avoid_weight.current;
        }
        avoidObjects() {
            let x = 0;
            let y = 0;
            for (let obstacle of obstacles) {
                if (Math.sqrt((this.x - obstacle[0]) ** 2 + (this.y - obstacle[1]) ** 2) <= view_distance.current / 2.5) {
                    x += this.x - obstacle[0];
                    y += this.y - obstacle[1];
                }
            }
            this.dx += x * avoid_weight.current;
            this.dy += y * avoid_weight.current;
        }
        match_v() {
            let dx = 0;
            let dy = 0;
            for (let neighbor of this.neighbors) {
                dx += neighbor.dx;
                dy += neighbor.dy;
            }
            if (this.neighbors.length > 0) {
                this.dx += (dx / this.neighbors.length) * matching_weight.current;
                this.dy += (dy / this.neighbors.length) * matching_weight.current;
            }
        }
        limit_speed() {
            let speed = Math.sqrt(this.dx ** 2 + this.dy ** 2);
            if (speed > speed_limit.current) {
                this.dx = (this.dx / speed) * speed_limit.current;
                this.dy = (this.dy / speed) * speed_limit.current;
            }
        }
        stay_in_bound() {
            if (this.x < margin)
                this.dx += turn_weight.current;
            if (this.x > width - margin)
                this.dx -= turn_weight.current;
            if (this.y < margin)
                this.dy += turn_weight.current;
            if (this.y > height - margin)
                this.dy -= turn_weight.current;
        }
        draw() {
            let theta = Math.atan2(this.dy, this.dx);
            ctx.current.translate(this.x, this.y);
            ctx.current.rotate(theta);
            ctx.current.scale((width / 1980) * (height / 900) * 1.5, (width / 1980) * (height / 900) * 1.5);
            ctx.current.translate(-this.x, -this.y);
            ctx.current.fillStyle = this.color;
            ctx.current.beginPath();
            ctx.current.moveTo(this.x, this.y);
            ctx.current.lineTo(this.x - 15, this.y + 5);
            ctx.current.lineTo(this.x - 15, this.y - 5);
            ctx.current.lineTo(this.x, this.y);
            ctx.current.fill();
            ctx.current.setTransform(1, 0, 0, 1, 0, 0);
        }
        toString() {
            return this.x + ", " + this.y;
        }
    }
    const init = () => {
        for (let i = 0; i < 350; i += 1) {
            boids.push(new Boid(
                Math.random() * width,
                Math.random() * height,
                Math.random() * 10 - 5,
                Math.random() * 10 - 5,
                "#558cf4"
            ));
        }
    };
    const distance = (boid1, boid2) => {
        return Math.sqrt((boid1.x - boid2.x) ** 2 + (boid1.y - boid2.y) ** 2);
    };
    const drawObstacle = (point) => {
        ctx.current.fillStyle = "Red";
        const scale = (width / 1920) * (height / 900);
        ctx.current.moveTo(point[0], point[1]);
        ctx.current.beginPath();
        ctx.current.arc(point[0] - 5 * scale, point[1] - 5 * scale, 10 * scale, 0, 2 * Math.PI);
        ctx.current.stroke();
        ctx.current.fill();
    };
    const timeStep = () => {
        ctx.current.clearRect(0, heightMin, width, height);
        for (let boid of boids) {
            boid.find_neighbors();
            boid.cluster();
            boid.avoid();
            boid.avoidObjects();
            boid.avoidMouse();
            boid.match_v();
            boid.limit_speed();
            boid.stay_in_bound();
            boid.x += boid.dx;
            boid.y += boid.dy;
            boid.draw();
        }
        for (let point of obstacles) {
            drawObstacle(point);
        }
        window.requestAnimationFrame(timeStep);
    };
    const sizeCanvas = () => {
        const canvas = canvasRef.current;
        width = window.innerWidth;
        height = window.innerHeight - navbarHeight;
        canvas.width = width;
        canvas.height = height;
        let scale = Math.min(Math.max((width / 1980) * (height / 900), 0.5), 1.5);
        speed_limit.current = 3 + 6 * scale;
        view_distance.current = 200 * scale;
        avoid_distance.current = 20 * scale;
        center_weight.current = 0.005 * scale;
        avoid_weight.current = 0.05 * scale;
        matching_weight.current = 0.05 * scale;
        turn_weight.current = 1;
        margin = 150 * scale;
    };
    const addObstacle = (e) => {
        const settingsModal = document.getElementById('settingsModal');
        if (settingsModal && settingsModal.classList.contains('show')) {
            return;
        }
        obstacles.push([e.clientX, e.clientY - navbarHeight]);
    };
    const getCursor = (e) => {
        cursor = [e.clientX, e.clientY - navbarHeight];
    };
    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbarHeight = navbar.offsetHeight;
        }
        console.log(navbarHeight);
        window.onpointermove = getCursor;
        window.onresize = sizeCanvas;
        window.onpointerdown = addObstacle;
        ctx.current = canvasRef.current.getContext("2d");
        const settings = JSON.parse(localStorage.getItem('boidSettings'));
        if (settings) {
            speed_limit.current = settings.speed_limit;
            view_distance.current = settings.view_distance;
            avoid_distance.current = settings.avoid_distance;
            center_weight.current = settings.center_weight;
            avoid_weight.current = settings.avoid_weight;
            matching_weight.current = settings.matching_weight;
            turn_weight.current = settings.turn_weight;
            document.getElementById('speed_limit').value = settings.speed_limit;
            document.getElementById('view_distance').value = settings.view_distance;
            document.getElementById('avoid_distance').value = settings.avoid_distance;
            document.getElementById('center_weight').value = settings.center_weight;
            document.getElementById('avoid_weight').value = settings.avoid_weight;
            document.getElementById('matching_weight').value = settings.matching_weight;
            document.getElementById('turn_weight').value = settings.turn_weight;
        }
        sizeCanvas();
        init();
        window.requestAnimationFrame(timeStep);
        return () => {
            window.onpointermove = null;
            window.onresize = null;
            window.onpointerdown = null;
        };
    }, []);
    return (
        <>
            <canvas id="canvas" ref={canvasRef}></canvas>
        </>
    );
};
export default Boids;