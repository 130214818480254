import React, { useRef } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const TimelinePage = () => {
  const tempImage = 'https://via.placeholder.com/150';
  const timelineData = [
    {
      images: [tempImage, tempImage, tempImage],
      location: 'London, England',
      date: 'September 4, 2022',
      coordinates: [51.509865, -0.118092],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Southend-on-Sea, England',
      date: 'Septermber 18, 2022',
      coordinates: [51.5378, 0.7143],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Canterbury, England',
      date: 'September 24, 2022',
      coordinates: [51.279999, 1.080000],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Newcastle upon Tyne, England',
      date: 'September 30, 2022',
      coordinates: [54.966667, -1.600000],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Florence, Italy',
      date: 'October 28, 2022',
      coordinates: [43.769562, 11.255814],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Geneva, Switzerland',
      date: 'November 7, 2022',
      coordinates: [46.204391, 6.143158],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Chamonix, France',
      date: 'November 8, 2022',
      coordinates: [47.018711, 12.34256],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Lyon, France',
      date: 'November 10, 2022',
      coordinates: [45.763420, 4.834277],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Copenhagen, Denmark',
      date: 'November 24, 2022',
      coordinates: [55.676098, 12.568337],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Malmo, Sweden',
      date: 'November 26, 2022',
      coordinates: [55.6033166, 13.0013362],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Aberdeen, Scotland',
      date: 'December 4, 2022',
      coordinates: [57.149651, -2.099075],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Brighton, England',
      date: 'January 14, 2023',
      coordinates: [50.82253, -0.137163],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Milan, Italy',
      date: 'January 20, 2023',
      coordinates: [45.464664, 9.188540],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'York, England',
      date: 'Febuary 4, 2023',
      coordinates: [53.958332, -1.080278],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Munich, Germany',
      date: 'Febuary 18, 2023',
      coordinates: [48.137154, 11.576124],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Garmisch, Germany',
      date: 'Febuary 19, 2023',
      coordinates: [47.4921, 11.0958],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'St. Anton, Austria',
      date: 'Febuary 21, 2023',
      coordinates: [47.129635, 10.268179],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Budapest, Hungary',
      date: 'March 3, 2023',
      coordinates: [47.497913, 19.040236],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Rome, Italy',
      date: 'April 4, 2023',
      coordinates: [41.90, 12.49],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Vatican City',
      date: 'April 6, 2023',
      coordinates: [47.018711, 12.34256],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Vienna, Austria',
      date: 'April 8, 2023',
      coordinates: [48.210033, 16.363449],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Prague, Czechia',
      date: 'April 10, 2023',
      coordinates: [50.073658, 14.418540],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Belgrade, Serbia',
      date: 'April 13, 2023',
      coordinates: [44.787197, 20.457273],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Bucharest, Romania',
      date: 'April 14, 2023',
      coordinates: [44.439663, 26.096306],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Transylvania, Romania',
      date: 'April 15, 2023',
      coordinates: [46.766666, 23.583332],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Marbella, Spain',
      date: 'April 30, 2023',
      coordinates: [36.516666, -4.883333],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Tangier, Morocco',
      date: 'May 1, 2023',
      coordinates: [47.018711, 12.34256],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Gibraltar',
      date: 'May 2, 2023',
      coordinates: [35.9463391, -5.655601],
    },
    {
      images: [tempImage, tempImage, tempImage],
      location: 'Malaga, Spain',
      date: 'May 5, 2023',
      coordinates: [36.719444, -4.420000],
    },
  ];

  const mapRef = useRef();

  const handleScroll = (coordinates) => {
    if (mapRef.current) {
      mapRef.current.flyTo(coordinates, 10, { duration: 2 });
    }
  };

  return (
    <div className="timeline-page">
      <MapContainer
        center={[20, 0]}
        zoom={2}
        scrollWheelZoom={false}
        style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0, zIndex: -1 }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
      </MapContainer>
      <div className="container">
        <h1>Timeline</h1>
        <VerticalTimeline>
          {timelineData.map((data, index) => (
            <VerticalTimelineElement
              key={index}
              date={data.date}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              onTimelineElementClick={() => handleScroll(data.coordinates)}
            >
              <h3 className="vertical-timeline-element-title">{data.location}</h3>
              <div className="timeline-images">
                {data.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={`timeline-${imgIndex}`} className="timeline-image" />
                ))}
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default TimelinePage;